<template>
  <CRow>
    <CCol md="3">
      <CCard style="min-height: 600px;">
        <CCardHeader>
          <slot name="header">
            <CIcon name="cil-user"/> {{caption}}
            <div class="card-header-actions"></div>
          </slot>
        </CCardHeader>
        <CCardBody>
          <div class="container-fluid" style="padding: 10px; text-align: center;">
            <img :src="logo_thumb" style="width: 200px;">
            <br><br>
            {{$v.form.name.$model}}
            <br><br>
            {{$v.form.rfc.$model}}
            <br><br>
            {{$v.form.fiscal_name.$model}}
            <br><br>
            <br><br>
            TIMBRES DISPONIBLES
            <br><br>
            {{stamps}}
            <br><br>
            {{deadline | date}}
          </div>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol md="9">
      <CCard style="min-height: 600px;">
        <CCardBody>
          <ul class="nav nav-tabs justify-content-end">
            <li @click="changeTab('general')" class="nav-item">
              <a class="nav-link" :class="tab == 'general' ? 'active' : ''" aria-current="page">Información General</a>
            </li>
            <li @click="changeTab('logo')" class="nav-item">
              <a class="nav-link" :class="tab == 'logo' ? 'active' : ''">Logotipo</a>
            </li>        
            <li @click="changeTab('password')" class="nav-item">
              <a class="nav-link" :class="tab == 'password' ? 'active' : ''">Contraseña</a>
            </li>        
          </ul>
          <br>
          <div v-show="tab == 'general'" class="container-fluid" style="padding: 10px;">
            <CForm>
              <CRow>
              <CCol md="6">
                <CSelect
                  label="Tipo de Cuenta: "
                  :value.sync="$v.form.fiscal_type.$model"
                  :options="typeOptions"
                  disabled
                />
              </CCol>
              <CCol md="6">
                <CSelect
                  label="Zona Horaria: "
                  :value.sync="$v.form.huso_horario.$model"
                  :options="timeOptions"
                />
              </CCol>
             </CRow>
              <CRow>
                <CCol md="4">
                  <CInput
                    label="RFC:"
                    :lazy="false"
                    :value.sync="$v.form.rfc.$model"
                    :isValid="checkIfValid('rfc')"
                    placeholder=""
                    autocomplete="rfc"
                    invalidFeedback="Este campo es requerido y debe ser un RFC válido."
                />
                </CCol>
                <CCol md="4">
                  <CInput
                    label="Nombre o Razón Social:"
                    :lazy="false"
                    :value.sync="$v.form.fiscal_name.$model"
                    :isValid="checkIfValid('fiscal_name')"
                    placeholder=""
                    autocomplete="fiscal_name"
                    invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
                </CCol>
                <CCol md="4">
                  <CInput
                    label="Nombre Comercial:"
                    :lazy="false"
                    :value.sync="$v.form.name.$model"
                    :isValid="checkIfValid('name')"
                    placeholder=""
                    autocomplete="name"
                    invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
                </CCol>
              </CRow>
              <CRow>
                <CCol md="4">
                  <CInput
                    label="Persona de Contacto:"
                    :lazy="false"
                    :value.sync="$v.form.contact_name.$model"
                    :isValid="checkIfValid('contact_name')"
                    placeholder=""
                    autocomplete="contact_name"
                    invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
                </CCol>
                <CCol md="4">
                  <CInput
                    label="Teléfono o Celular:"
                    :lazy="false"
                    :value.sync="$v.form.phone.$model"
                    :isValid="checkIfValid('phone')"
                    placeholder=""
                    autocomplete="phone"
                    invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
                </CCol>
                <CCol md="4">
                  <CInput
                    label="Correo Electrónico:"
                    :lazy="false"
                    :value.sync="$v.form.email.$model"
                    :isValid="checkIfValid('email')"
                    placeholder=""
                    autocomplete="email"
                    invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
                </CCol>
              </CRow>
              <CRow><CCol md="12"><br><br></CCol></CRow>
              <CRow>
              <CCol md="4">
                <CInput
                  label="Calle:"
                  :lazy="false"
                  :value.sync="$v.form.street.$model"
                  placeholder=""
                  autocomplete="street"
              />
              </CCol>
              <CCol md="2">
                <CInput
                  label="No. Exterior:"
                  :lazy="false"
                  :value.sync="$v.form.exterior.$model"
                  placeholder=""
                  autocomplete="exterior"
              />
              </CCol>
              <CCol md="2">
                <CInput
                  label="No. Interior:"
                  :lazy="false"
                  :value.sync="$v.form.interior.$model"
                  placeholder=""
                  autocomplete="interior"
              />
              </CCol>
              <CCol md="4">
                <CInput
                  label="Colonia:"
                  :lazy="false"
                  :value.sync="$v.form.colony.$model"
                  placeholder=""
                  autocomplete="colony"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="4">
                <CInput
                  label="Municipio:"
                  :lazy="false"
                  :value.sync="$v.form.municipality.$model"
                  placeholder=""
                  autocomplete="municipality"
                />
              </CCol>
              <CCol md="4">
                <CSelect
                  label="Estado: "
                  :value.sync="$v.form.state.$model"
                  :options="statesOptions"
                />
              </CCol>
              <CCol md="4">
                <CInput
                  label="C.P.:"
                  :lazy="false"
                  :value.sync="$v.form.zipcode.$model"
                  :isValid="checkIfValid('zipcode')"
                  placeholder=""
                  autocomplete="zipcode"
                  maxLength="5"
                  invalidFeedback="Este campo debe estar conformado 5 dígitos numéricos."
              />
              </CCol>
             </CRow>
             <CRow><CCol md="12"><br></CCol></CRow>
             <CRow>
              <CCol md="12">
                <CButton @click="storeInfoForm" color="info" style="float: right;">Actualizar Información</CButton>
              </CCol>
            </CRow>
            </CForm>
          </div>
          <div v-show="tab == 'logo'" class="container-fluid" style="padding: 10px;">
            <CForm>
              <CRow>
                <CCol md="12">
                  <img :src="logo" style="width: 400px;">
                  <br><br>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="12">
                <form id="logo_form" method="POST" enctype="multipart/form-data">
                  <CInput
                    type="file"
                    name="logo"
                    id="logo"
                    label="Nuevo Logotipo:"
                    :lazy="false"
                    :value.sync="$v.form.logo.$model"
                    placeholder=""
                    autocomplete="logo"
                />
                </form>
                </CCol>
              </CRow>
              <CRow><CCol md="12"><br></CCol></CRow>
              <CRow>
                <CCol md="12">
                  <CButton @click="storeLogoForm" color="info" style="float: right;">Actualizar Logotipo</CButton>
                </CCol>
              </CRow>
            </CForm>
          </div>
          <div v-show="tab == 'password'" class="container-fluid" style="padding: 10px;">
            <MyPasswordForm ref="passwordForm"></MyPasswordForm>
          </div>                  
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import ws from '../../services/products';
import store from '../../store';
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email} from "vuelidate/lib/validators"
import general_ws from '../../services/general';
import account_ws from '../../services/account';
import MyPasswordForm from './MyPasswordForm.vue'

export default {
  name: 'MyAccountForm',
  components: { MyPasswordForm },
  props: {
    caption: {
      type: String,
      default: 'Table'
    },
  },
  data() {
    return {
      tab: 'general',
      openForm: false,
      isEdit: false,
      form: this.getEmptyForm(),
      form_passwords: {
          password: '',
          password2: '',
          password3: ''
      },
      stamps: 0,
      deadline: '2023-12-31',
      submitted: false,
      toasts: [],
      states: [],
      statesOptions: [],
      countries: [],
      countriesOptions: [],
      typeOptions: [{label: 'Persona Física', value: 'physical'}, {label: 'Persona Moral', value: 'moral'}],
      timeOptions: [{label: 'Horario del Centro', value: 'CENTRO'}, {label: 'Horario del Pacífico', value: 'PACIFICO'}, {label: 'Horario del Noroeste', value: 'NOROESTE'}, {label: 'Horario del Sureste', value: 'SURESTE'}],
      logo: 'https://orderly-facturador.s3.us-west-2.amazonaws.com/assets/logo.png',
      logo_thumb: 'https://orderly-facturador.s3.us-west-2.amazonaws.com/assets/logo.png'
    }
  },
  mounted: async function() {
    this.loading();

    let response = await account_ws.getInfo(); 

    if(response.type == "success"){
      this.form.contact_name = response.data.name;
      this.form.email = response.data.email;
      this.form.phone = response.data.phone;
      this.form.fiscal_type = response.data.fiscal_type;
      this.form.huso_horario = response.data.huso_horario;

      this.stamps = response.data.details.stamps;
      this.deadline = response.data.details.deadline;

      if(response.data.logo != ''){
        this.logo = response.data.url_logo;
      }
      else{
        this.logo = 'https://orderly-facturador.s3.us-west-2.amazonaws.com/assets/logo.png';
      }
      
      if(response.data.logo_thumb != ''){
        this.logo_thumb = response.data.url_thumb;
      }
      else{
        this.logo_thumb = 'https://orderly-facturador.s3.us-west-2.amazonaws.com/assets/logo.png';
      }
    }

    let response1 = await account_ws.getFiscalInfo(); 

    if(response1.type == "success"){
      this.form.rfc = response1.data.rfc;
      this.form.name = response1.data.comercial_name;
      this.form.fiscal_name = response1.data.fiscal_name;
      
      this.form.street = response1.data.street;
      this.form.exterior = response1.data.exterior;
      this.form.interior = response1.data.interior;
      this.form.colony = response1.data.colony;
      this.form.locality = response1.data.locality;
      this.form.municipality = response1.data.municipality;
      this.form.state = response1.data.state;
      this.form.country = response1.data.country;
      this.form.zipcode = response1.data.zipcode;
    }

    let response2 = await general_ws.getStates(); 

    if(response2.type == "success"){
      this.states = response2.data;
    }

    let response3 = await general_ws.getCountries(); 

    if(response3.type == "success"){
      this.countries = response3.data;
    }

    this.statesOptions = await this.$parseSelectOptionsOrdered(this.states, "code", "name");
    this.countriesOptions = await this.$parseSelectOptionsOrdered(this.countries, "code", "name");

    this.form.country = "MEX";

    this.loaded();
  },
  methods: {
    async storeInfoForm () {
      this.$v.$touch();

      if (this.isValid) {
        this.loading();

        this.submitted = true;

        let response = await account_ws.setInfo(this.form);

        this.loaded();
        this.showToast(response.type, response.message);

        //location.reload();
      }
    },
    async storeLogoForm () {
      if(this.form.logo != undefined){
        this.loading();

        const form = document.getElementById("logo_form");

        const formData = new FormData(form);

        let response = await account_ws.changeLogo(formData);

        let data = response.data;

        if(response.type == "success"){
          this.logo = data.url_logo;
          this.logo_thumb = data.url_thumb;

          this.form.logo = '';
        }

        this.loaded();
        this.showToast(response.type, response.message);
      }
    },
    async updatePassword (password_data) {
      this.loading();

      let response = await account_ws.setPassword(password_data);

      let data = response.data;

      if(response.type == "success"){
        this.logo = data.url_logo;
      }

      this.loaded();
      this.showToast(response.type, response.message);
    },
    changeTab (tab) {
      if(this.tab != tab){
        this.tab = tab;
      }
    },
    getEmptyForm () {
      return {
          id: 0,
          rfc: '',
          name: '',
          fiscal_type: 'physical',
          fiscal_name: '',
          huso_horario: 'CENTRO',
          contact_name: '',
          address: '',
          exterior: '',
          interior: '',
          colony: '',
          city: '',
          zipcode: '',
          state: 'JAL',
          country: 'MEX',
          email: '',
          phone: ''
      }
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    }
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
    isPassValid () { return !this.$v.form_passwords.$invalid },
    isPassDirty () { return this.$v.form_passwords.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      rfc: {
        required,
        minLength: minLength(12),
        maxLength: maxLength(13)
      },
      fiscal_name: {
        required,
        minLength: minLength(3)
      },
      name: {
        required,
        minLength: minLength(3)
      },
      contact_name: {},
      huso_horario: {},
      fiscal_type: {},
      phone: {
        integer,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      email: {
        email
      },
      street: {},
      exterior: {},
      interior: {},
      colony: {},
      municipality: {},
      zipcode: {
        required,
        integer,
        minLength: minLength(5),
        maxLength: maxLength(5)
      },
      state: {},
      country: {},
      logo: {},
    },
    form_passwords: {
      password: {
        required,
        minLength: minLength(6)
      },
      password2: {
        required,
        minLength: minLength(6)
      },
      password3: {
        required,
        minLength: minLength(6)
      },
    }
  },
}
</script>
